.filter-select-data-component {
  display: flex;
  align-items: flex-start; // Alinea todos los elementos hijos en la parte superior
}

.filter-select-data-component__btn-filter {
  border: 1px solid;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  // margin-bottom: 10px; // Para alinear correctamente con los chips
}

.filter-select-data-component__options-border {
  border: 1px solid;
}

.filter-select-data-component__padding {
  padding: 20px;
}

.filter-select-data-component__border-radius {
  border-radius: 5px;
}

.filter-select-data-component__margin-top {
  margin-top: 20px;
}

.filter-select-data-component__margin-right {
  margin-right: 20px;
}

.filter-select-data-component__margin-bottom {
  // margin-bottom: 20px;
}

.filter-select-data-component__chip {
  padding: 0.5rem;
  background-color: #f0f0f0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  user-select: none; // Evita la selección de texto y cambios de cursor
}

.filter-select-data-component__btn-back {
  color: #666;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.filter-select-data-component__flex {
  display: flex;
}

.filter-select-data-component__align-items-center {
  align-items: center;
}
