.t-row {
    .row-item {
        display: flex;
        flex-direction: row;
        .row-cell {
            display: flex;
            justify-content: center;
            align-items: center;
            // width: $width-row;

            .row-text {
            }

            .row-symbol {
                margin-left: 5px;
            }
        }

        .type-number {
            justify-content: flex-end;
        }
    }

    .row-check {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        // width: 40px;
    }
}
