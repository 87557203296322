.bolita-roja {
    // background-color: red;
    div {
        margin-right: 5px;

        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: red;
    }
}
.bolita-azul {
    div {
        margin-right: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: rgb(44, 137, 208);
    }
}
.bolita-verde {
    div {
        margin-right: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: rgb(12, 187, 12);
    }
}
.bolita-naranja {
    div {
        margin-right: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: orange;
    }
}
