$wideSideBar: 350px;

.sidebar-visible {
    transition: max-width 0.5s ease-in-out;
    max-width: $wideSideBar;
    flex: 0 0 $wideSideBar;
}

.sidebar-hidden {
    transition: max-width 0.5s ease-in-out;
    max-width: 0;
    overflow: hidden;
}

.sidebar-content {
    max-width: $wideSideBar;
    overflow: hidden;
}

// side

.conversation-side-content {
    width: $wideSideBar;
}

.container-conversation-side {
    display: flex;
    flex-direction: column;
    height: 800px; /* Altura total del contenedor */
}

.scrollable-content {
    flex: 1; /* Esto hace que este elemento ocupe todo el espacio disponible */
    overflow-y: auto; /* Habilita el desplazamiento vertical cuando el contenido excede la altura máxima */
}

// item

.scrollable-content {
    max-height: auto; /* Ajusta este valor a la altura máxima que deseas */
    overflow-y: auto; /* Habilita el desplazamiento vertical cuando el contenido excede la altura máxima */
}

.box-conversation-single {
    // background-color: rgb(90, 183, 220);
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Alinea los elementos al principio (izquierda) en el eje transversal (vertical) */
    justify-content: flex-start; /* Alinea los elementos al principio (arriba) en el eje principal (horizontal) */
    border-bottom: 1px gray solid;
}

.height-important {
    // height: 60px;
}

.gray-background {
    background-color: gray;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lightblue-background {
    background-color: lightblue;
}

// new

/* styles.scss */
.conversation-side-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: "auto";
}

.List {
    flex-grow: 1;
    overflow: auto;
}

.custom-size {
    font-size: 7px;
}
