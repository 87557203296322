.btn-circle {
    width: 50px; /* o el tamaño que desees */
    height: 50px;

    border-radius: 50%;
}

.drop-zone-wp {
    // display: flex;
    // justify-content: center;
    // align-items: center;


    position: relative;

    .file-uploaded div {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        button.drop-zone {
            display: none;
        }
    }

    img {
        width: auto;
        max-width: 99%;
        margin: 0 auto 10px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 99%;
        background-color: white;
    }
    .loading-img img {
        position: inherit;
    }

    .drop-zone {
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: rgb(255, 255, 255);
        // background-color: rgb(224, 224, 224);

        border: none;
        color: #666;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
        min-height: 125px;
        padding: 0;
        overflow: hidden;
        position: relative;

        .material-icons {
            position: relative;
            top: 4px;
            font-size: 20px;
        }

        &:hover {
            border-style: dotted;
            border-width: 1px;

            border-color: rgb(0, 141, 206);
        }
    }

    .btn-danger {
        position: absolute;
        right: 7px;
        top: 5px;
        z-index: 999;
    }

    .file-over {
        border-style: dotted;
        border-width: 1px;
        border-color: rgb(0, 141, 206);
    }

    &.dropzone-bkg-white {
        color: red;
        display: flex;
        justify-content: center;

        .block-document-to-upload {
            background-color: transparent;

            .btn-delete {
                right: 15px;
                top: 35%;
            }
        }

        img {
            max-width: 100px;
        }
    }
}

.no-edit {
    .drop-zone-wp {
        &:after {
            content: "";
            display: block;
            background: transparent;
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 10;
            top: 0;
            left: 0;
        }
    }

    .drop-zone {
        border: 2px solid #ededed;
    }

    .btn-danger {
        display: none;
    }
}

.drop-zone-wp {
    img {
        max-width: 100%;
    }
}

.drag-drop-box-customized {

    // display: flex;
    // justify-content: center;
    // align-items: center;



    width: 100%;
    min-height: 250px;
    padding: 60px 20px;
    text-align: center;
    border-radius: 10px;
    border: solid 1px #ededed;
    background-color: #fcfcfc;

    p {
        color: #d5d5d5;
        a {
            color: rebeccapurple;
            text-decoration: underline;
        }
    }
}
