.t-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .pagination-itemperpage {
        padding: 10px;

        .itemperpage-label {
            margin-right: 8px;
        }

        .itemperpage-select {
        }
    }

    .pagination-controlpage {
        padding: 10px;
        display: flex;
        align-items: center;

        .controlpage-group {
            display: flex;
            align-items: center;

            .controlpage-button-several {
                display: flex;
                align-items: center;
            }

            .controlpage-button-single {
                display: flex;
                align-items: center;
            }

            .disabled-button {
                color: rgb(169, 169, 169);
            }
        }

        .controlpage-counter {
            margin-right: 8px;
            margin-left: 8px;
        }
    }
}
