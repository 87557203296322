.t-header {
    display: flex;

    .header-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        // width: $width-row;
        background-color: $color-header;
        border: 1px solid rgb(181, 181, 181);
        border-right: 1px solid transparent;

        .header-text {
            color: $color-text;
        }

        .header-icon {
            color: $color-text;
            margin-left: 5px;
        }
    }

    .header-check {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        // width: 40px;
    }
}
