html, body, #root, .c-app {
    height: 100%;
  }
  
  .c-app {
    display: flex;
    flex-direction: column;
  }
  
  .c-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .c-body {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .c-main {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .c-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .c-row {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    overflow: auto;
    padding: 20px;
  }
  