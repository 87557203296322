.table-container {
  overflow-x: auto;
  // white-space: nowrap;
  // white-space: nowrap;

  .test {
    // width: 100%;
    border-collapse: collapse;
    overflow-x: auto;
  }
}
