// If you want to override variables do it here
@import "variables";

$enable-ltr: true; /* stylelint-disable-line scss/dollar-variable-default */
$enable-rtl: true; /* stylelint-disable-line scss/dollar-variable-default */

// Import styles
@import "@coreui/coreui-pro/scss/coreui";
@import "@coreui/coreui-pro/scss/themes/dark";

@import "layout";

// If you want to add custom CSS you can put it here.
@import "custom";

// Estilos de CORE UI modificados

// Es necesario porque por defecto el señor de la librería le ha puesto un padding más grande que mi cabeza
.vr {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

// Cambia de manera global el el color del sidebar
.sidebar {
    // ORIGINAL NIMO
    // --cui-sidebar-bg: #0a1215 !important;
    // COLOR ALGO MÁS CLARO
    --cui-sidebar-bg: #101d23 !important;
}

// Cambia de manera global el color de los botones marcados de la paginación
.pagination {
    --bs-pagination-active-bg: #101d23 !important;
    --bs-pagination-active-border-color: #101d23 !important;

    // Páginas no seleccionadas
    --bs-pagination-color: #101d23 !important;
    // hover
    --bs-pagination-hover-color: #616161 !important;
}

.calendar {
    --cui-calendar-cell-selected-bg: #101d23 !important;
    --cui-calendar-cell-range-bg: rgba(16, 29, 35, 0.1) !important;
    // hover
    --cui-calendar-cell-hover-bg: #c3c3c3 !important;
    --cui-calendar-cell-range-hover-bg: #c3c3c325 !important;
}

// Checkbox de los multiselect
.form-multi-select {
    --cui-form-multi-select-option-selected-indicator-bg: #101d23 !important;
    --cui-form-multi-select-option-selected-indicator-border-color: #101d23 !important;
}

// Checkbox de las tablas (Afecta al check principal) Estos checks son generados por mí
.form-check-input:checked {
    background-color: #101d23 !important;
    border-color: #101d23 !important;
}

// Checkbox de las tablas (Afecta al check principal) Estos checks son generados por mí
.form-check-input:indeterminate {
    background-color: #101d23 !important;
    border-color: #101d23 !important;
}
